<mat-form-field class="w-full">
  <mat-label [ngClass]="{ '!text-white': isWhite }">{{ 'registration-page.activity-type' | translate }}*</mat-label>
  <mat-chip-grid #chipGrid [disabled]="disabled">
    <mat-chip-row
      *ngFor="let activityType of selectedActivityTypes"
      (removed)="removeActivityType(activityType)"
      [removable]="chipIsRemovable">
      {{ 'registration-page.activity-type-option.' + activityType | translate }}
      <button matChipRemove *ngIf="chipIsRemovable" [attr.aria-label]="'remove ' + activityType">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    [disabled]="disabled"
    #activityTypeInput
    [formControl]="activityFilterControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectActivityTypes($event)">
    <mat-option *ngFor="let activityType of filteredActivityTypes | async" [value]="activityType">
      {{ 'registration-page.activity-type-option.' + activityType | translate }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
