import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslateLoaderService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly httpClient: HttpClient,
  ) {}

  loadTranslation(module: string): Observable<unknown> {
    return this.httpClient
      .get(`/assets/i18n/${localStorage.getItem('language') ?? environment.defaultLanguage}.json`)
      .pipe(
        tap(resp =>
          this.translateService.setTranslation(
            localStorage.getItem('language') ?? environment.defaultLanguage,
            resp,
            true,
          ),
        ),
      );
  }
}
