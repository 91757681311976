import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { SharedModule } from '../../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'dfarm-activity-type-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    SharedModule,
    TranslateModule,
  ],
  templateUrl: './activity-type-selector.component.html',
  styleUrl: './activity-type-selector.component.scss',
})
export class ActivityTypeSelectorComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() isWhite: boolean = false;
  @Input({ required: true }) chipIsRemovable: boolean;
  @Input() inputActivityTypes: string[] = [];
  @Output() emitSelectedActivityTypes: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild('activityTypeInput') activityTypeInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activityFilterControl = new FormControl('');
  filteredActivityTypes: Observable<string[]>;
  selectedActivityTypes: string[] = [];
  allActivityTypes: string[] = ['farmer', 'consultant', 'integrator', 'distributor', 'manufacturer', 'vendor'];

  ngOnInit(): void {
    this.selectedActivityTypes = this.inputActivityTypes;
    this.filteredActivityTypes = this.activityFilterControl.valueChanges.pipe(
      startWith(null),
      map((activityType: string | null) =>
        activityType ? this._filterActivityTypes(activityType) : this.allActivityTypes.slice(),
      ),
    );
  }

  removeActivityType(activityType: string): void {
    const index = this.selectedActivityTypes.indexOf(activityType);

    if (index >= 0) {
      this.selectedActivityTypes.splice(index, 1);
      this.emitSelectedActivityTypes.emit(this.selectedActivityTypes);
    }
  }

  selectActivityTypes(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedActivityTypes.includes(event.option.value)) {
      this.selectedActivityTypes.push(event.option.value);
      this.emitSelectedActivityTypes.emit(this.selectedActivityTypes);
    }
    this.activityTypeInput.nativeElement.value = '';
    this.activityFilterControl.setValue(null);
  }

  private _filterActivityTypes(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allActivityTypes.filter(activityType => activityType.toLowerCase().includes(filterValue));
  }
}
