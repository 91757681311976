import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dfarmDateToLocalISO } from 'src/app/shared/utils/utils';
import { environment } from '../../../../environments/environment';
import {
  IAgroMonitoringSatelliteImageDataViewModel,
  IAgroMonitoringSoilDataViewModel,
  IAgroMonitoringUviDataViewModel,
  IAgroMonitoringWeatherDataViewModel,
  ISatelliteImageDataPointViewModel,
  ISatelliteImageDataViewModel,
  ISatelliteImageStats,
  ISoilDataPointViewModel,
  IUviDataPointViewModel,
  IWeatherDataPointViewModel,
} from '../models/agromonitoring-data.model';

@Injectable({
  providedIn: 'root',
})
export class AgroMonitoringDataService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getAgroMonitoringWeatherData(
    landId: string,
    intervalStart: Date,
    intervalEnd: Date,
  ): Observable<IAgroMonitoringWeatherDataViewModel> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = this.baseUrl + 'agromonitoring-data/' + landId + '/weather?' + 'from=' + startDate + '&to=' + endDate;

    return this.http.get<IAgroMonitoringWeatherDataViewModel>(url).pipe(
      map((dto): IAgroMonitoringWeatherDataViewModel => {
        const weatherDataViewModel: IAgroMonitoringWeatherDataViewModel = {
          dataPoints: dto.dataPoints.map(dataPoint => {
            const weatherDataPointViewModel: IWeatherDataPointViewModel = {
              temperature: dataPoint.temperature,
              precipitation: dataPoint.precipitation,
              humidity: dataPoint.humidity,
              datetime: new Date(dataPoint.datetime),
            };

            return weatherDataPointViewModel;
          }),
        };

        return weatherDataViewModel;
      }),
    );
  }

  getAgroMonitoringSoilData(
    landId: string,
    intervalStart: Date,
    intervalEnd: Date,
  ): Observable<IAgroMonitoringSoilDataViewModel> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = this.baseUrl + 'agromonitoring-data/' + landId + '/soil?' + 'from=' + startDate + '&to=' + endDate;

    return this.http.get<IAgroMonitoringSoilDataViewModel>(url).pipe(
      map((dto): IAgroMonitoringSoilDataViewModel => {
        const soilDataViewModel: IAgroMonitoringSoilDataViewModel = {
          dataPoints: dto.dataPoints.map(dataPoint => {
            const soilDataPointViewModel: ISoilDataPointViewModel = {
              temperatureSurface: dataPoint.temperatureSurface,
              temperatureDepth10: dataPoint.temperatureDepth10,
              datetime: new Date(dataPoint.datetime),
            };

            return soilDataPointViewModel;
          }),
        };

        return soilDataViewModel;
      }),
    );
  }

  getAgroMonitoringSatelliteImageData(
    landId: string,
    intervalStart: Date,
    intervalEnd: Date,
  ): Observable<IAgroMonitoringSatelliteImageDataViewModel> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url =
      this.baseUrl + 'agromonitoring-data/' + landId + '/satellite-image?' + 'from=' + startDate + '&to=' + endDate;

    return this.http.get<IAgroMonitoringSatelliteImageDataViewModel>(url).pipe(
      map((dto): IAgroMonitoringSatelliteImageDataViewModel => {
        const satelliteImageDataViewModel: IAgroMonitoringSatelliteImageDataViewModel = {
          dataPoints: dto.dataPoints.map(dataPoint => {
            const temperatureDataPointViewModel: ISatelliteImageDataPointViewModel = {
              images: dataPoint.images.map(imageDataPoint => {
                const stats: ISatelliteImageStats = {
                  ndvi: {
                    min: imageDataPoint.stats.ndvi.min,
                    mean: imageDataPoint.stats.ndvi.mean,
                    max: imageDataPoint.stats.ndvi.max,
                  },
                };

                const imageData: ISatelliteImageDataViewModel = {
                  type: imageDataPoint.type,
                  dataCoverage: imageDataPoint.dataCoverage,
                  cloudCoverage: imageDataPoint.cloudCoverage,
                  imageNDVI: imageDataPoint.imageNDVI,
                  imageTrueColor: imageDataPoint.imageTrueColor,
                  stats: stats,
                };

                return imageData;
              }),
              datetime: new Date(dataPoint.datetime),
            };

            return temperatureDataPointViewModel;
          }),
        };

        return satelliteImageDataViewModel;
      }),
    );
  }

  getAgroMonitoringUviData(
    landId: string,
    intervalStart: Date,
    intervalEnd: Date,
  ): Observable<IAgroMonitoringUviDataViewModel> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = this.baseUrl + 'agromonitoring-data/' + landId + '/uvi?' + 'from=' + startDate + '&to=' + endDate;

    return this.http.get<IAgroMonitoringUviDataViewModel>(url).pipe(
      map((dto): IAgroMonitoringUviDataViewModel => {
        const uviDataViewModel: IAgroMonitoringUviDataViewModel = {
          dataPoints: dto.dataPoints.map(dataPoint => {
            const uviDataPointViewModel: IUviDataPointViewModel = {
              uvi: dataPoint.uvi,
              datetime: new Date(dataPoint.datetime),
            };

            return uviDataPointViewModel;
          }),
        };

        return uviDataViewModel;
      }),
    );
  }
}
