import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CORE_FEATURE_KEY, State } from './core.reducer';

// Lookup the 'Core' feature state managed by NgRx
export const getCoreState = createFeatureSelector<State>(CORE_FEATURE_KEY);

export const getSideMenuIsOpen = createSelector(getCoreState, (state: State) => state.sideMenuIsOpen);
export const getAddFieldPanelIsOpen = createSelector(getCoreState, (state: State) => state.addFieldPanelIsOpen);
export const getFieldInspectorPanelIsOpen = createSelector(getCoreState, (state: State) => state.fieldInspectorIsOpen);
export const getDfarmServicesPanelIsOpen = createSelector(getCoreState, (state: State) => state.dfarmServicesIsOpen);
export const getInformationPanelIsOpen = createSelector(getCoreState, (state: State) => state.informationPanelIsOpen);
export const getDataViewerPanelIsOpen = createSelector(getCoreState, (state: State) => state.dataViewerPanelIsOpen);
export const getSatelliteViewerPanelIsOpen = createSelector(
  getCoreState,
  (state: State) => state.satelliteViewerPanelIsOpen,
);
export const getSensorViewerPanelIsOpen = createSelector(getCoreState, (state: State) => state.sensorViewerPanelIsOpen);
export const getWaterscopeItemListPanelIsOpen = createSelector(
  getCoreState,
  (state: State) => state.waterScopeItemListPanelIsOpen,
);
export const getWaterscopeTablePanelIsOpen = createSelector(
  getCoreState,
  (state: State) => state.waterScopeTablePanelIsOpen,
);
export const getWaterscopeChartPanelIsOpen = createSelector(
  getCoreState,
  (state: State) => state.waterScopeChartPanelIsOpen,
);
export const getSoilProbePanelIsOpen = createSelector(getCoreState, (state: State) => state.soilProbePanelIsOpen);
