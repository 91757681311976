<div class="flex flex-col items-center h-full text-center text-dark-green">
  <form [formGroup]="dateRangeSelectorForm" class="w-full text-sm mb-2 flex justify-evenly items-center">
    <div class="text-dark-green">
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value" />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value" />
      </label>
    </div>
  </form>
  <div class="h-full w-full flex flex-col overflow-auto padding-2 rounded-lg">
    <div class="relative bg-off-white shadow-sunkenBoxShadow rounded-lg">
      <canvas #ndviCanvas id="ndviCanvas"></canvas>
    </div>
  </div>
  <div class="w-full flex justify-evenly items-center mt-2 mb-4 text-dark-green">
    <div class="flex justify-around">
      <div class="text-base mr-2.5">
        {{ 'dashboard-page.satellite-viewer-panel.vegetation' | translate }}
      </div>
      <div>
        <button
          (click)="switchOff(false)"
          class="w-10 rounded-l-md text-xs p-1 text-dark-green"
          [ngClass]="{ 'bg-dark-green text-off-white': !ndviSwitch }">
          {{ 'dashboard-page.satellite-viewer-panel.off' | translate }}
        </button>
        <button
          (click)="switchOff(true)"
          class="w-10 rounded-r-md text-dark-green text-xs p-1"
          [ngClass]="{ 'bg-light-green text-off-white': ndviSwitch }">
          {{ 'dashboard-page.satellite-viewer-panel.on' | translate }}
        </button>
      </div>
    </div>
    <div class="flex justify-center items-center text-dark-green" *ngIf="selectedSatelliteImageData === undefined">
      {{ 'dashboard-page.satellite-viewer-panel.no-data' | translate }}
    </div>
    <div class="flex justify-around" *ngIf="selectedSatelliteImageData !== undefined">
      <button
        class="cursor-pointer mr-2.5"
        (click)="selectPreviousSatelliteImageData()"
        [ngClass]="{ 'opacity-0': selectedSatelliteImageDataIndex === 0 }">
        <img class="h-5" src="../../../../../assets/quick-viewer-icons/navi-left.svg" />
      </button>
      <div class="text-base text-center text-dark-green" *ngIf="selectedSatelliteImageData !== undefined">
        {{ selectedSatelliteImageData.datetime | date: 'yyyy.MM.dd.' }}
      </div>
      <button
        class="cursor-pointer ml-2.5"
        (click)="selectNextSatelliteImageData()"
        [ngClass]="{
          'opacity-0': selectedSatelliteImageDataIndex === satelliteImageDataSelector.dataPoints.length - 1,
        }">
        <img class="h-5" src="../../../../../assets/quick-viewer-icons/navi-right.svg" />
      </button>
    </div>
  </div>
</div>
