<dfarm-panel
  *ngIf="!isLoading"
  [isClosable]="true"
  [isLeftSide]="false"
  [isMultiViewPanel]="true"
  [isVisible]="panelIsOpen"
  class="parent-panel">
  <div class="child-panel">
    <div class="title text-xl">Öntözők listázása</div>

    <form [formGroup]="dateRangeSelectorForm" class="w-full text-sm mb-2 flex justify-evenly items-center text-white">
      <div>
        <label>
          <input
            [max]="formControls.endDate.value"
            class="bg-transparent w-28 text-center text-sm"
            formControlName="startDate"
            required
            type="date" />
        </label>
        -
        <label>
          <input
            [max]="maxDate"
            [min]="formControls.startDate.value"
            class="bg-transparent w-28 text-center text-sm"
            formControlName="endDate"
            required
            type="date" />
        </label>
      </div>
    </form>

    <div class="soil-laboratory-table overflow-auto p-4 pt-0">
      <table [dataSource]="dataSource" class="w-full bg-dark-gray" mat-table>
        <!-- Field Name Column -->
        <ng-container matColumnDef="fieldName">
          <th *matHeaderCellDef class="text-white text-lg !px-2" mat-header-cell>Tábla</th>
          <td *matCellDef="let element" class="text-white !px-2 rounded-l-lg" mat-cell>
            {{ element.fieldName }}
          </td>
          <td *matFooterCellDef mat-footer-cell>Összes öntözött mennyiség</td>
        </ng-container>

        <!-- Irrigated Amount Column -->
        <ng-container matColumnDef="irrigatedAmount">
          <th *matHeaderCellDef class="text-white text-lg !px-2" mat-header-cell>Öntözött mennyiség</th>
          <td *matCellDef="let element" class="text-white !px-2" mat-cell>
            {{ element.irrigatedAmount }} m<sup>3</sup>
          </td>
          <td *matFooterCellDef mat-footer-cell>{{ totalIrrigatedAmount() }} m<sup>3</sup></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: displayedColumns"
          class="hover:bg-dark-green hover:cursor-pointer transition-all duration-300"
          mat-row></tr>
        <tr *matFooterRowDef="displayedColumns" mat-footer-row></tr>
      </table>
    </div>
  </div>
</dfarm-panel>
