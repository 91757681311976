import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dfarmDateToLocalISO } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import {
  GatewayCumulativeWaterAmountViewModel,
  GatewayHumidityViewModel,
  GatewayPressureViewModel,
  GatewayTemperatureViewModel,
  GatewayUviViewModel,
  GatewayWaterAmountViewModel,
  GatewayWindSpeedViewModel,
  SensorDataResolutionEnum,
  SoilMoistureViewModel,
  SoilTemperatureViewModel,
} from '../models/sensor-data.model';

@Injectable({
  providedIn: 'root',
})
export class GatewayDataService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // Get Gateway Data
  getGatewayTemperature(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)
    // let params = new HttpParams();

    // params = params.append('from', startDate);
    // params = params.append('to', endDate);
    // params = params.append('resolution', resolution);

    const url = `${this.baseUrl}sensor-data/gateway/temperature/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayTemperatureViewModel>(url).pipe(
      map((dto): GatewayTemperatureViewModel => {
        const gatewayTemperatureViewModel: GatewayTemperatureViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return gatewayTemperatureViewModel;
      }),
    );
  }

  getGatewaySoilTemperature(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/soil-temperature/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilTemperatureViewModel>(url).pipe(
      map((dto): SoilTemperatureViewModel => {
        const gatewaySoilTemperatureViewModel: SoilTemperatureViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return gatewaySoilTemperatureViewModel;
      }),
    );
  }

  getGatewayWaterAmount(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/water-amount/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayWaterAmountViewModel>(url).pipe(
      map((dto): GatewayWaterAmountViewModel => {
        const gatewayWaterAmountViewModel: GatewayWaterAmountViewModel = {
          raw: {
            time: dto.raw.time.map(time => new Date(time)),
            values: dto.raw.values,
          },
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            sum: dto.hourly.sum,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            sum: dto.daily.sum,
          },
        };

        return gatewayWaterAmountViewModel;
      }),
    );
  }

  getGatewayCumulativeWaterAmount(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/cumulative-water-amount/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayCumulativeWaterAmountViewModel>(url).pipe(
      map((dto): GatewayCumulativeWaterAmountViewModel => {
        const gatewayCumulativeWaterAmountViewModel: GatewayCumulativeWaterAmountViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            sum: dto.hourly.sum,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            sum: dto.daily.sum,
          },
        };

        return gatewayCumulativeWaterAmountViewModel;
      }),
    );
  }

  getGatewaySoilMoisture(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/soil-moisture/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<SoilMoistureViewModel>(url).pipe(
      map((dto): SoilMoistureViewModel => {
        const gatewaySoilMoistureViewModel: SoilMoistureViewModel = {
          raw: {
            time: dto.raw.time.map(time => new Date(time)),
            values: dto.raw.values,
          },
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return gatewaySoilMoistureViewModel;
      }),
    );
  }

  getGatewayHumidity(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/humidity/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayHumidityViewModel>(url).pipe(
      map((dto): GatewayHumidityViewModel => {
        const gatewayHumidityViewModel: GatewayHumidityViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            average: dto.daily.average,
          },
        };

        return gatewayHumidityViewModel;
      }),
    );
  }

  getGatewayPressure(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/pressure/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayPressureViewModel>(url).pipe(
      map((dto): GatewayPressureViewModel => {
        const gatewaySoilMoistureViewModel: GatewayPressureViewModel = {
          raw: {
            time: dto.raw.time.map(time => new Date(time)),
            values: dto.raw.values,
          },
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
        };

        return gatewaySoilMoistureViewModel;
      }),
    );
  }

  getGatewayWindSpeed(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/wind-speed/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayWindSpeedViewModel>(url).pipe(
      map((dto): GatewayWindSpeedViewModel => {
        const gatewaySoilMoistureViewModel: GatewayWindSpeedViewModel = {
          raw: {
            time: dto.raw.time.map(time => new Date(time)),
            values: dto.raw.values,
          },
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
        };

        return gatewaySoilMoistureViewModel;
      }),
    );
  }

  getGatewayUvi(
    locationId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<any> {
    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/gateway/uvi/${locationId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<GatewayUviViewModel>(url).pipe(
      map((dto): GatewayUviViewModel => {
        const gatewaySoilMoistureViewModel: GatewayUviViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
        };

        return gatewaySoilMoistureViewModel;
      }),
    );
  }

  // getGatewaySoilConductivity(
  //   locationId: string,
  //   intervalStart: Date,
  //   intervalEnd: Date,
  //   resolution: SensorResolutionEnum
  // ): Observable<any> {
  //   const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
  //   const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)
  //   let params = new HttpParams();

  //   params = params.append('from', startDate);
  //   params = params.append('to', endDate);
  //   params = params.append('resolution', resolution);

  //   const url = `${this.baseUrl}sensor-data/gateway/soil-conductivity/${locationId}`;

  //   return this.http.get<any>(url, { params });
  // }
}
