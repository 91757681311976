import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import * as CoreActions from '../../../../core/state/core.actions';
import { CorePartialState } from '../../../../core/state/core.reducer';
import * as CoreQueries from '../../../../core/state/core.selectors';
import { ISatelliteImageDataViewModel } from '../../models/agromonitoring-data.model';
import { FieldDetailsViewModel } from '../../models/field.model';
import { DashboardService } from '../../services/dashboard.service';
import * as DashboardActions from '../../state/dashboard.actions';
import { DashboardPartialState } from '../../state/dashboard.reducer';
import { BasePanelComponent } from '../base-panel.component';
import { EditFieldDialogComponent } from './components/edit-field-dialog/edit-field-dialog.component';

@Component({
  selector: 'dfarm-field-inspector',
  templateUrl: './field-inspector.component.html',
  styleUrls: ['./field-inspector.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FieldInspectorComponent extends BasePanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fields: FieldDetailsViewModel[];
  @Output() selectFieldOutput = new EventEmitter<FieldDetailsViewModel>();
  @Output() unselectField = new EventEmitter<any>();
  @Output() selectSatelliteImagesOutput = new EventEmitter<ISatelliteImageDataViewModel[]>();

  filteredFields: FieldDetailsViewModel[];
  activePanel = 'panel1';

  searchField = new FormControl('');
  selectedField: FieldDetailsViewModel;

  constructor(
    public dialog: MatDialog,
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly translateService: TranslateService,
    private dashboardService: DashboardService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      // GET Field Inspector Menu is open
      this.coreStore.pipe(select(CoreQueries.getFieldInspectorPanelIsOpen)).subscribe(panelIsOpen => {
        if (!panelIsOpen) {
          this.activePanel = 'panel1';
        }
        this.panelIsOpen = panelIsOpen;
        this.isLoading = false;
      }),
    );
    this.subs.push(
      this.searchField.valueChanges.pipe(debounceTime(500)).subscribe(searchInput => {
        this.filteredFields = this.fields.filter(field => field.name.toLowerCase().includes(searchInput.toLowerCase()));
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredFields = changes.fields.currentValue;
  }

  selectField(field: FieldDetailsViewModel) {
    this.selectFieldOutput.emit(field);
    this.selectedField = field;
    this.activePanel = 'panel2';
  }

  backToList() {
    this.unselectField.emit();
    this.activePanel = 'panel1';
  }

  deleteField(fieldId: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: this.getTranslationTitle('are-you-sure'),
      panelClass: 'custom-dialog',
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.dashboardService
          .deleteField(fieldId)
          .subscribe(() => this.dashboardStore.dispatch(DashboardActions.updateDashboardContent()));
      }
    });
  }

  editFieldName(fieldId: string, oldFieldName: string) {
    const dialogRef = this.dialog.open(EditFieldDialogComponent, {
      width: '500px',
      data: oldFieldName,
      panelClass: 'custom-dialog',
    });

    dialogRef.afterClosed().subscribe(fieldName => {
      if (fieldName) {
        this.dashboardService.editField(fieldId, fieldName).subscribe(() => this.loadData());
      }
    });
  }

  showAddFieldPanel(): void {
    this.coreStore.dispatch(CoreActions.closeAllPanel());
    this.coreStore.dispatch(CoreActions.openAddFieldPanel());
    this.coreStore.dispatch(CoreActions.closeSideMenu());
  }

  private getTranslationTitle = (translationPath: string): string =>
    this.translateService.instant(`dashboard-page.field-inspector-panel.${translationPath}`);

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private loadData() {
    this.dashboardStore.dispatch(DashboardActions.updateDashboardContent());
  }

  forwardSelectedSatelliteImages(satelliteImages: ISatelliteImageDataViewModel[]) {
    this.selectSatelliteImagesOutput.emit(satelliteImages);
  }
}
