<div class="flex flex-col items-center h-full text-center text-dark-green w-full p-2">
  <form [formGroup]="dateRangeSelectorForm" class="w-full text-sm mb-2 flex justify-evenly items-center">
    <div class="text-dark-green">
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value" />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value" />
      </label>
    </div>
  </form>
  <div class="h-full w-[90%] flex flex-col padding-2 desktop:w-[95%] padding-2 rounded-lg">
    <div *ngIf="!hasSensorDataLocation" class="flex h-[95%] w-full justify-center items-center text-dark-green">
      {{ 'dashboard-page.sensor-viewer-panel.no-sensor-data' | translate }}
    </div>

    <!-- temperature -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #temperatureCanvas
        id="temperatureCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }">
      </canvas>
      <div *ngIf="getChartResolution(chartNameEnum.temperatureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.temperatureCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.temperatureCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- soil temperature -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType !== undefined,
        '!h-0': selectedSensorDataLocation.deviceType === undefined,
      }">
      <canvas
        #soilTemperatureCanvas
        id="soilTemperatureCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType === undefined,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilTemperatureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilTemperatureCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.soilTemperatureCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- water amount -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #waterAmountCanvas
        id="waterAmountCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.waterAmountCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.waterAmountCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.waterAmountCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- cumulative water amount -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #cumulativeWaterAmountCanvas
        id="cumulativeWaterAmountCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.cumulativeWaterAmountCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.cumulativeWaterAmountCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.cumulativeWaterAmountCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- soil moisture -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType !== undefined,
        '!h-0': selectedSensorDataLocation.deviceType === undefined,
      }">
      <canvas
        #soilMoistureCanvas
        id="soilMoistureCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType === undefined,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilMoistureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilMoistureCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.soilMoistureCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- humidity -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #humidityCanvas
        id="humidityCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.humidityCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.humidityCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.humidityCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- pressure -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #pressureCanvas
        id="pressureCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.pressureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.pressureCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.pressureCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- wind speed -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #windSpeedCanvas
        id="windSpeedCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.windSpeedCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.windSpeedCanvas).resolutions"
          (resolutionChanged)="
            changeChartResolution($event, chartNameEnum.windSpeedCanvas)
          "></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- uvi -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': selectedSensorDataLocation.deviceType === DeviceTypeEnum.Gateway,
        '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
      }">
      <canvas
        #uviCanvas
        id="uviCanvas"
        [ngClass]="{
          '!h-0': selectedSensorDataLocation.deviceType !== DeviceTypeEnum.Gateway,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.uviCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.uviCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.uviCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
  </div>
</div>
