<div class="aszf-container">
  <div class="aszf-container-inner">
    <h1>1. INTRODUCTORY</h1>
    <p>
      The operator of www.dFarm.eu - hereinafter: the Website - dFuture Solutions Bt. recognizes as binding on itself.
      The Data Controller shall take all measures reasonably expected of it in order to ensure the security of the
      personal data processed by it. Please read this Privacy Statement before using our Website, which contains an
      understandable way to handle your personal information! In the Privacy Statement, the Data Controller clearly and
      in detail inform data subjects of all relevant data processing issues fact. During the operation of the website,
      the Data Controller is the contracting authority and the initiator of the contact processes the data of
      individuals in order to provide them with an appropriate service. The service provider intends to comply fully
      with the legal requirements for the processing of personal data, in particular Regulation (EU) 2016/679 of the
      European Parliament and of the Council. This privacy statement is about the protection of personal data of natural
      persons and the data is free pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council
      year CXII. the law on the right to information self-determination and freedom of information. Name of service
      provider, data controller Name: dFuture Solutions Bt. Headquarters: 5600 Békéscsaba, Petőfi utca 12. Contact
      details of the data controller Name: dFuture Solutions Betéti Társaság Headquarters: 5600 Békéscsaba, Petőfi utca
      12. E-mail: szepmarcell&#64;dfuture.hu
    </p>

    <h1>2. DEFINITIONS</h1>
    <p>
      személyes adat: azonosított vagy azonosítható természetes személyre (érintett) vonatkozó bármely információ;
      azonosítható az a természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például
      név, szám, helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai,
      szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható;
      érintett: bármely meghatározott, személyes adat alapján azonosított vagy – közvetlenül vagy közvetve –
      azonosítható természetes személy az érintett hozzájárulása: az érintett akaratának önkéntes, konkrét és megfelelő
      tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést
      félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes adatok
      kezeléséhez; adatkezelő: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,
      amely a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza; ha az
      adatkezelés céljait és eszközeit az uniós vagy a tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő
      kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami jog is meghatározhatja. adatkezelés: az
      alkalmazott eljárástól függetlenül az adaton végzett bármely művelet vagy a műveletek összessége, így különösen
      gyűjtése, felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, felhasználása, lekérdezése, továbbítása,
      nyilvánosságra hozatala, összehangolása vagy összekapcsolása, zárolása, törlése és megsemmisítése, valamint az
      adat további felhasználásának megakadályozása, fénykép-, hang- vagy képfelvétel készítése, valamint a személy
      azonosítására alkalmas fizikai jellemzők (pl. ujj- vagy tenyérnyomat, DNS-minta, íriszkép) rögzítése adattörlés:
      az adat felismerhetetlenné tétele oly módon, hogy a helyreállítása többé nem lehetséges adatfeldolgozó: az a
      természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében
      személyes adatokat kezel; adatfeldolgozás: az adatkezelési műveletekhez kapcsolódó technikai feladatok elvégzése,
      függetlenül a műveletek végrehajtásához alkalmazott módszertől és eszköztől, valamint az alkalmazás helyétől,
      feltéve hogy a technikai feladatot az adaton végzik adatállomány: az egy nyilvántartásban kezelt adatok összessége
      adatvédelmi incidens: a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes
      adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az
      azokhoz való jogosulatlan hozzáférést eredményezi. címzett: az a természetes vagy jogi személy, közhatalmi szerv,
      ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a személyes adatot közlik, függetlenül attól, hogy
      harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a tagállami joggal
      összhangban férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az említett adatok e közhatalmi
      szervek általi kezelése meg kell, hogy feleljen az adatkezelés céljainak megfelelően az alkalmazandó adatvédelmi
      szabályoknak; harmadik fél: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb
      szerv, amely nem azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a személyekkel, akik az
      adatkezelő vagy adatfeldolgozó közvetlen irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak.
      információs társadalommal összefüggő szolgáltatás: elektronikus úton, távollevők részére, rendszerint
      ellenszolgáltatás fejében nyújtott szolgáltatás, amelyhez a szolgáltatás igénybe vevője egyedileg fér hozzá
      elektronikus kereskedelmi szolgáltatás: olyan információs társadalommal összefüggő szolgáltatás, amelynek célja
      valamely birtokba vehető forgalomképes ingó dolog - ideértve a pénzt és az értékpapírt, valamint a dolog módjára
      hasznosítható természeti erőket -, szolgáltatás, ingatlan, vagyoni értékű jog (a továbbiakban együtt: áru)
      üzletszerű értékesítése, beszerzése, cseréje vagy más módon történő igénybevétele. GDPR (General Data Protection
      Regulation): az Európai Unió új Adatvédelmi Rendelete;
    </p>

    <h1>3. FELHASZNÁLÓK KÖRE</h1>
    <p>
      Felhasználó, a Weblap szolgáltatásait igénybevevő, bármely meghatározott, személyes adat alapján azonosított vagy
      - közvetlenül vagy közvetve - azonosítható természetes személy.
    </p>

    <h1>4. AZ ADATKEZELÉS IRÁNYELVEI</h1>
    <p>
      Az adatkezelő kijelenti, hogy az adatkezelési tájékoztatóban foglaltak szerint végzi a személyes adatok kezelését
      és betartja a vonatkozó jogszabályok előírásait, különös figyelemmel az alábbiakra: A személyes adatok kezelését
      jogszerűen és tisztességesen, valamint az érintett számára átlátható módon kell végezni. A személyes adatok
      gyűjtése csak meghatározott, egyértelmű és jogszerű célból történhet. A személyes adatok kezelésének célja
      megfelelő és releváns legyen, és csak a szükséges mértékű lehet. A személyes adatoknak pontosnak és naprakésznek
      kell lenniük. A pontatlan személyes adatokat haladéktalanul törölni kell. A személyes adatok tárolásának olyan
      formában kell történnie, hogy az érintettek azonosítását csak szükséges ideig tegye lehetővé. A személyes adatok
      ennél hosszabb ideig történő tárolására csak akkor kerülhet sor, ha a tárolás közérdekű archiválás céljából,
      tudományos és történelmi kutatási célból vagy statisztikai célból történik. A személyes adatok kezelését oly módon
      kell végezni, hogy megfelelő technikai vagy szervezési intézkedések alkalmazásával biztosítva legyen a személyes
      adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével,
      megsemmisítésével vagy károsodásával szembeni védelmet is ideértve. Az adatvédelem elveit minden azonosított vagy
      azonosítható természetes személyre vonatkozó információ esetében alkalmazni kell.
    </p>

    <h1>5. FONTOS ADATKEZELÉSI INFORMÁCIÓK</h1>
    <p>
      Az adatkezelés célja: kapcsolattartás, információ és többletszolgáltatás nyújtása. Az adatkezelés jogalapja: az
      érintett személy hozzájárulása. Az adatkezelésben érintettek köre: a weboldal regisztrációs felhasználói (űrlapon
      keresztül érdeklődők). Az adatkezelés időtartama és az adatok törlése: Az adatkezelés időtartama mindig a konkrét
      felhasználói cél függvénye, de az adatokat haladéktalanul törölni kell, ha az eredetileg kitűzött cél már
      megvalósult. Az adatkezeléshez történő hozzájárulását az érintett személy bármikor visszavonhatja a
      kapcsolattartási e-mail címre küldött levélben. Amennyiben a törlésnek jogszabályi akadálya nincs, ez esetben
      adatai törlésre kerülnek. Az adatok megismerésére jogosultak: az adatkezelő és alkalmazottai. Az érintett személy
      kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését, törlését
      vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen, valamint az érintett
      adathordozhatósághoz való jogáról. Az érintett személy bármely időpontban visszavonhatja az adatkezelési
      hozzájárulását, de ez nem érinti a visszavonás előtt a hozzájárulás alapján végrehajtott adatkezelés
      jogszerűségét. Az érintett személy élhet a felügyeleti hatósághoz címzett panasz benyújtásának jogával. Amennyiben
      az érintett személy használni kívánja a kapcsolatfelfelvételt azaz igénybe kívánja venni a weboldal ez irányú
      szolgáltatását, szükséges a kért személyes adatok megadása. Az érintett személy nem köteles személyes adatokat
      megadni, az adatszolgáltatás elmaradása számára semmilyen hátrányos következménnyel nem jár. Az érintett személy
      jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül helyesbítse illetve kiegészítse a rá
      vonatkozó pontatlan személyes adatokat. Az érintett személy jogosult arra, hogy kérésére az adatkezelő
      indokolatlan késedelem nélkül törölje a rá vonatkozó pontatlan személyes adatokat, az adatkezelő pedig köteles
      arra, hogy az érintettre vonatkozó személyes adatokat indokolatlan késedelem nélkül törölje, amennyiben az
      adatkezelésnek nincs más jogalapja. A személyes adatok módosítása vagy törlése kezdeményezhető e-mailben,
      telefonon vagy levélben a fentebb megadott elérhetőségi lehetőségeken.
    </p>

    <h1>6. WEBOLDALON TALÁLHATÓ KAPCSOLATI ŰRLAP KITÖLTÉSE</h1>
    <p>
      Az Adatkezelő a weboldalon keresztül beérkező kapcsolati űrlap által hozzáfért adatokat kizárólag kapcsolattartás
      és információ nyújtás céljából használja fel, az adatokat adatbázisban nem tárolja.
    </p>

    <h1>7. ADATTOVÁBBÍTÁS, ADATOK ÖSSZEKAPCSOLÁSA</h1>
    <p>
      Az Adatkezelő nem ad el, nem ad bérbe és semmilyen formában nem bocsájt rendelkezésére a Felhasználóra vonatkozó
      személyes adatokat illetve információkat más cégnek, magánszemélynek. Az Adatkezelő a tőle elvárható módon
      gondoskodik az adatok megfelelő biztonságáról, és megteszi azokat a technikai és szervezési intézkedéseket, melyek
      az adatvédelmi szabályok és elvek érvényre juttatását garantálják és a személyes adatok biztonságát elősegítik.
      Tájékoztatjuk a Tisztelt Felhasználókat arról, hogy az Adatkezelő személyes adatot kizárólag az érintett
      hozzájárulásával továbbít harmadik személynek vagy személyeknek.
    </p>

    <h1>8. AZ ADATKEZELÉSSEL KAPCSOLATOS JOGOK</h1>
    <p>
      A tájékoztatás kéréshez való jog Ön a megadott elérhetőségeken keresztül tájékoztatást kérhet tőlünk, hogy cégünk
      milyen adatait, milyen jogalapon, milyen adatkezelési cél miatt, milyen forrásból, mennyi ideig kezeli. Az Ön
      kérelmére haladéktalanul, de legfeljebb 30 napon belül, az Ön által megadott e-mail elérhetőségre tájékoztatást
      küldünk. A helyesbítéshez való jog Ön a megadott elérhetőségeken keresztül kérheti tőlünk hogy valamely adatát
      módosítsuk. Erről az Ön kérelmére haladéktalanul, de legfeljebb 30 napon belül intézkedünk, az Ön által megadott
      e-mail elérhetőségre tájékoztatást küldünk. A törléshez való jog Ön a megadott elérhetőségeken keresztül kérheti
      tőlünk adatának törlését. Az Ön kérelmére ezt haladéktalanul, de legfeljebb 30 napon belül megtesszük, az Ön által
      megadott e-mail elérhetőségre tájékoztatást küldünk. A zároláshoz való jog Ön a megadott elérhetőségeken keresztül
      kérheti tőlünk adatának zárolását. A zárolás addig tart, amíg az Ön által megjelölt indok szükségessé teszi az
      adatok tárolását. Az Ön kérelmére ezt haladéktalanul, de legfeljebb 30 napon belül megtesszük, az Ön által
      megadott e-mail elérhetőségre tájékoztatást küldünk. A tiltakozáshoz való jog Ön a megadott elérhetőségeken
      keresztül tiltakozhat az adatkezelés ellen. A tiltakozást a kérelem benyújtásától számított legrövidebb időn
      belül, de legfeljebb 15 napon belül megvizsgáljuk, annak megalapozottsága kérdésében döntést hozunk, és a
      döntéséről Önt e-mailben tájékoztatjuk.
    </p>

    <h1>9. AZ ADATKEZELÉSSEL KAPCSOLATOS JOGÉRVÉNYESÍTÉSI LEHETŐSÉG</h1>
    <p>
      Az Ön által tapasztalt jogellenes adatkezelés esetén értesítse cégünket, így lehetőség nyílik arra, hogy rövid
      időn belül helyreálljon a jogszerű állapot. Az Ön érdekében mindent megteszünk, hogy a vázolt probléma
      megoldódjon. Amennyiben az Ön megítélése szerint a jogszerű állapot nem állítható helyre, értesítse erről a
      hatóságot az alábbi elérhetőségeken: Nemzeti Adatvédelmi és Információszabadság Hatóság Postacím: 1530 Budapest,
      Pf.: 5. Cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c Telefon: +36 (1) 391-1400 Fax: +36 (1) 391-1410 E-mail:
      ugyfelszolgalat (kukac) naih.hu URL https://naih.hu Koordináták: É 47°30'56''; K 18°59'57''
    </p>

    <h1>10. AZ ADATKEZELÉS ALAPJÁUL SZOLGÁLÓ JOGSZABÁLYOK</h1>
    <p>
      - AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016. április 27.) a természetes személyeknek a
      személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a
      95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet). - 2011. évi CXII. törvény az
      információs önrendelkezési jogról és az információszabadságról. - A köziratokról, a közlevéltárakról és a
      magánlevéltári anyag védelméről szóló 1995. évi LXVI. törvény. - A közfeladatot ellátó szervek iratkezelésének
      általános követelményeiről szóló 335/2005. (XII. 29.) Korm. rendelet. - 2001. évi CVIII. törvény az elektronikus
      kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről. -
      2003. évi C. törvény az elektronikus hírközlésről. Jelen Adatvédelmi Nyilatkozat hatályba lépésének időpontja:
      2020.03.31. Az adatkezelő a változtatás jogát fenntartja.
    </p>
  </div>
</div>
