<div class="flex flex-col items-center h-full text-center text-dark-green w-full">
  <form [formGroup]="dateRangeSelectorForm" class="w-full text-sm mb-2 flex justify-evenly items-center">
    <div class="text-dark-green">
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value" />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value" />
      </label>
    </div>
  </form>
  <div class="h-full w-full flex flex-col padding-2 padding-2 rounded-lg">
    <div *ngIf="!hasDeviceLocation" class="flex h-[95%] w-full justify-center items-center text-dark-green">
      {{ 'dashboard-page.sensor-viewer-panel.no-sensor-data' | translate }}
    </div>

    <!-- temperature -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #temperatureCanvas id="temperatureCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.temperatureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.temperatureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.temperatureCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- water amount -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #waterAmountCanvas id="waterAmountCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.waterAmountCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.waterAmountCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.waterAmountCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- cumulative water amount -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #cumulativeWaterAmountCanvas id="cumulativeWaterAmountCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.cumulativeWaterAmountCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.cumulativeWaterAmountCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.cumulativeWaterAmountCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- humidity -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #humidityCanvas id="humidityCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.humidityCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.humidityCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.humidityCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- ambient light -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #ambientLightCanvas id="ambientLightCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.ambientLightCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.ambientLightCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.ambientLightCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- Leaf Moisture -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #leafMoistureCanvas id="leafMoistureCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.leafMoistureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.leafMoistureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.leafMoistureCanvas)" />
      </div>
    </div>
    <!-- Wind Speed -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #windSpeedCanvas id="windSpeedCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.windSpeedCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.windSpeedCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.windSpeedCanvas)" />
      </div>
    </div>
  </div>
</div>
