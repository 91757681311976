import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { dfarmDateToLocalISO } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import {
  ChartNameEnum,
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  ISoilMoistureForLandViewModel,
  ISoilTemperatureForLandViewModel,
  SensorDataLocations,
  SensorDataResolutionEnum,
} from '../models/sensor-data.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SensorDataService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getSensorDataLocations(intervalStart: Date, intervalEnd: Date, fieldId: string): Observable<SensorDataLocations> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/locations/${fieldId}?from=${startDate}&to=${endDate}`;

    return this.http.get<SensorDataLocations>(url);
  }

  getSoilTemperature(
    fieldId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<ISoilTemperatureForLandViewModel> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/soil-temperature/${fieldId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<ISoilTemperatureForLandViewModel>(url).pipe(
      map((dto): ISoilTemperatureForLandViewModel => {
        const soilTemperatureForLandViewModel: ISoilTemperatureForLandViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return soilTemperatureForLandViewModel;
      }),
    );
  }

  getSoilMoisture(
    fieldId: string,
    intervalStart: Date,
    intervalEnd: Date,
    resolution: SensorDataResolutionEnum,
  ): Observable<ISoilMoistureForLandViewModel> {
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    const startDate = encodeURIComponent(dfarmDateToLocalISO(intervalStart)); // encode url part because of timezone (e.g. +02:00)
    const endDate = encodeURIComponent(dfarmDateToLocalISO(intervalEnd)); // encode url part because of timezone (e.g. +02:00)

    const url = `${this.baseUrl}sensor-data/soil-moisture/${fieldId}?from=${startDate}&to=${endDate}&resolution=${resolution}`;

    return this.http.get<ISoilMoistureForLandViewModel>(url).pipe(
      map((dto): ISoilMoistureForLandViewModel => {
        const gatewaySoilMoistureViewModel: ISoilMoistureForLandViewModel = {
          hourly: {
            time: dto.hourly.time.map(time => new Date(time)),
            average: dto.hourly.average,
          },
          daily: {
            time: dto.daily.time.map(time => new Date(time)),
            average: dto.daily.average,
            minimum: dto.daily.minimum,
            maximum: dto.daily.maximum,
          },
        };

        return gatewaySoilMoistureViewModel;
      }),
    );
  }

  getChartResolutionMap(): ChartResolutionMap[] {
    return chartResolutionMap;
  }

  getChartSelectedResolutionMap(): ChartSelectedResolutionMap[] {
    return chartSelectedResolutionMap;
  }
}

export const chartResolutionMap: ChartResolutionMap[] = [
  {
    chartName: ChartNameEnum.temperatureCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.waterAmountCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.cumulativeWaterAmountCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.humidityCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.pressureCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly],
  },
  {
    chartName: ChartNameEnum.windSpeedCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly],
  },
  {
    chartName: ChartNameEnum.uviCanvas,
    resolutions: [SensorDataResolutionEnum.hourly],
  },
  {
    chartName: ChartNameEnum.soilTemperatureCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.soilMoistureCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.soilConductivityCanvas,
    resolutions: [SensorDataResolutionEnum.raw, SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.soilTemperatureForLandCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.soilMoistureForLandCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.leafMoistureCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
  {
    chartName: ChartNameEnum.windSpeedCanvas,
    resolutions: [SensorDataResolutionEnum.hourly, SensorDataResolutionEnum.daily],
  },
];

export const chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [
  {
    chartName: ChartNameEnum.temperatureCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.waterAmountCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.cumulativeWaterAmountCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.humidityCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.pressureCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.windSpeedCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.uviCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.soilTemperatureCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.soilMoistureCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.soilConductivityCanvas,
    selectedResolution: SensorDataResolutionEnum.raw,
  },
  {
    chartName: ChartNameEnum.soilTemperatureForLandCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.soilMoistureForLandCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.leafMoistureCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
  {
    chartName: ChartNameEnum.windSpeedCanvas,
    selectedResolution: SensorDataResolutionEnum.hourly,
  },
];
