import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DASHBOARD_FEATURE_KEY, State } from './dashboard.reducer';

// Lookup the 'Dashboard' feature state managed by NgRx
export const getDashboardState = createFeatureSelector<State>(DASHBOARD_FEATURE_KEY);

export const getAreaSizeLimit = createSelector(getDashboardState, (state: State) => state.areaSizeLimit);

export const getActivePanel = createSelector(getDashboardState, (state: State) => state.activePanel);

export const getSelectedCoordinates = createSelector(getDashboardState, (state: State) => state.selectedCoordinates);

export const getSelectedCoordinatesArea = createSelector(
  getDashboardState,
  (state: State) => state.selectedCoordinatesArea,
);

export const getDrawingOnMapFieldDesignationState = createSelector(
  getDashboardState,
  (state: State) => state.drawingOnMapFieldDesignationState,
);

export const getGPSFieldDesignationState = createSelector(
  getDashboardState,
  (state: State) => state.gpsFieldDesignationState,
);

export const getSelectedField = createSelector(getDashboardState, (state: State) => state.selectedField);

export const getSelectedSatelliteImageURL = createSelector(
  getDashboardState,
  (state: State) => state.selectedSatelliteImageURL,
);

export const getSensorDataLocations = createSelector(getDashboardState, (state: State) => state.sensorDataLocations);
export const getSelectedSensorDataLocation = createSelector(
  getDashboardState,
  (state: State) => state.selectedSensorDataLocation,
);

export const getSelectedSensorDataTab = createSelector(
  getDashboardState,
  (state: State) => state.selectedSensorDataTab,
);

export const getSelectedWateringDevice = createSelector(
  getDashboardState,
  (state: State) => state.selectedWateringDevice,
);

export const getDeviceLocations = createSelector(getDashboardState, (state: State) => state.deviceLocations);
export const getSelectedDeviceLocation = createSelector(
  getDashboardState,
  (state: State) => state.selectedDeviceLocation,
);
