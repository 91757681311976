<div class="flex flex-col items-center h-full text-center text-dark-green w-full">
  <form [formGroup]="dateRangeSelectorForm" class="w-full text-sm mb-2 flex justify-evenly items-center">
    <div class="text-dark-green">
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value" />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value" />
      </label>
    </div>
  </form>
  <div class="h-full w-full flex flex-col padding-2 padding-2 rounded-lg">
    <div *ngIf="!hasDeviceLocation" class="flex h-[95%] w-full justify-center items-center text-dark-green">
      {{ 'dashboard-page.sensor-viewer-panel.no-sensor-data' | translate }}
    </div>

    <!-- soil temperature -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #soilTemperatureCanvas
        id="soilTemperatureCanvas"
        [ngClass]="{
          '!h-0': selectedDeviceLocation.deviceType === undefined,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilTemperatureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilTemperatureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.soilTemperatureCanvas)" />
      </div>
    </div>

    <!-- soil moisture -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #soilMoistureCanvas id="soilMoistureCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilMoistureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilMoistureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.soilMoistureCanvas)" />
      </div>
    </div>

    <!-- soil potential -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #soilPotentialCanvas id="soilPotentialCanvas"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilPotentialCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilPotentialCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.soilPotentialCanvas)" />
      </div>
    </div>
  </div>
</div>
