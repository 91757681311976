export function dfarmDelay(ms: number): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function dfarmDateToLocalISO(date: Date): string {
  const off = date.getTimezoneOffset();
  const absoff = Math.abs(off);
  return (
    new Date(date.getTime() - off * 60 * 1000).toISOString().substring(0, 23) +
    (off > 0 ? '-' : '+') +
    Math.floor(absoff / 60)
      .toFixed(0)
      .padStart(2, '0') +
    ':' +
    (absoff % 60).toString().padStart(2, '0')
  );
}
