<div class="dialog-wrapper">
  <div class="form-title">{{ 'dialogs.profile.title' | translate }}</div>
  <form [formGroup]="editProfileFormGroup" class="edit-profile-form" *ngIf="!isLoading">
    <div class="left-form">
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.lastname' | translate }}
        </label>
        <input
          name="name"
          type="text"
          [ngClass]="{
            '!bg-off-white !text-dark-green !border-none': formControls.lastName.disabled,
          }"
          [formControl]="formControls.lastName" />
      </div>
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.firstname' | translate }}
        </label>
        <input
          name="name"
          type="text"
          [ngClass]="{
            '!bg-off-white !text-dark-green !border-none': formControls.firstName.disabled,
          }"
          [formControl]="formControls.firstName" />
      </div>
      <dfarm-activity-type-selector
        [chipIsRemovable]="false"
        [inputActivityTypes]="formControls.activityTypes.value"
        [disabled]="formControls.activityTypes.disabled"
        (emitSelectedActivityTypes)="updateActivityType($event)" />
    </div>
    <div class="right-form">
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.email' | translate }}
        </label>
        <input
          name="email"
          type="email"
          [ngClass]="{
            '!bg-off-white !text-dark-green !border-none': formControls.email.disabled,
          }"
          [formControl]="formControls.email" />
      </div>
      <div class="input-wrapper">
        <label class="field-name-label">
          {{ 'dialogs.profile.phone-number' | translate }}
        </label>
        <input
          name="phone"
          type="text"
          [ngClass]="{
            '!bg-off-white !text-dark-green !border-none': formControls.phoneNumber.disabled,
          }"
          [formControl]="formControls.phoneNumber" />
      </div>
      <dfarm-language-selector></dfarm-language-selector>
    </div>
  </form>
  <div class="buttons">
    <ng-container *ngIf="!isEditing">
      <dfarm-button (click)="close()" [darkGreenButton]="true">
        {{ 'dialogs.profile.close' | translate }}
      </dfarm-button>
      <dfarm-button (click)="editForm(true)" [lightGreenButton]="true">
        {{ 'dialogs.profile.edit' | translate }}
      </dfarm-button>
    </ng-container>
    <ng-container *ngIf="isEditing">
      <dfarm-button (click)="close()" [darkGreenButton]="true">
        {{ 'dialogs.profile.cancel' | translate }}
      </dfarm-button>
      <dfarm-button (click)="save()" [lightGreenButton]="true">
        {{ 'dialogs.profile.save' | translate }}
      </dfarm-button>
    </ng-container>
  </div>
</div>
