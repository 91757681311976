import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IEditProfileViewModel } from '../../models/edit-profile.model';
import { ProfileService } from '../../services/profile.service';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { ButtonComponent } from '../button/button.component';
import { ActivityTypeSelectorComponent } from '../activity-type-selector/activity-type-selector.component';

@Component({
  selector: 'dfarm-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LanguageSelectorComponent,
    ButtonComponent,
    ActivityTypeSelectorComponent,
  ],
})
export class ProfileDialogComponent implements OnInit {
  formControls: Record<keyof IEditProfileViewModel, FormControl> = {
    email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
    phoneNumber: new FormControl({ value: '', disabled: true }, Validators.required),
    activityTypes: new FormControl({ value: [], disabled: true }, Validators.required),
    firstName: new FormControl({ value: '', disabled: true }, Validators.required),
    lastName: new FormControl({ value: '', disabled: true }, Validators.required),
  };
  editProfileFormGroup = new FormGroup(this.formControls);

  isEditing = false;
  isLoading = false;

  constructor(
    private readonly profileService: ProfileService,
    private readonly translate: TranslateService,
    public dialogRef: MatDialogRef<ProfileDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.profileService.getUser().subscribe(res => {
      this.editProfileFormGroup.patchValue(res, { emitEvent: false });
      this.isLoading = false;
    });
  }

  save() {
    if (this.editProfileFormGroup.valid) {
      this.profileService
        .updateUser({
          email: this.formControls.email.value,
          phoneNumber: this.formControls.phoneNumber.value,
        } as IEditProfileViewModel)
        .subscribe(() => this.close());
    }
  }

  close() {
    this.editForm(false);
    this.dialogRef.close();
  }

  editForm(isEditing: boolean): void {
    this.isEditing = isEditing;
    isEditing
      ? this.formControls.phoneNumber.enable({ emitEvent: false })
      : this.formControls.phoneNumber.disable({ emitEvent: false });
  }

  updateActivityType(activityTypes: string[]): void {
    this.formControls.activityTypes.patchValue(activityTypes);
  }
}
