<div class="flex flex-col justify-between h-full">
  <div class="text-dark-green text-center h-full flex items-center">
    {{ 'dashboard-page.add-field-panel.press-start' | translate }}
  </div>

  <div *ngIf="init && !designating" class="button-wrapper">
    <button
      class="light-button w-full"
      (click)="startGPSFieldDesignation()"
      [disabled]="startGPSFieldDesignationDisabled">
      {{ 'dashboard-page.add-field-panel.start' | translate }}
    </button>
  </div>
  <div *ngIf="!init && designating" class="button-wrapper">
    <button class="light-button w-full" (click)="finishFieldDesignation()">
      {{ 'dashboard-page.add-field-panel.end-of-selection' | translate }}
    </button>
  </div>
  <div *ngIf="!init && !designating" class="button-wrapper">
    <button class="dark-button" (click)="resetFieldDesignation()">
      {{ 'dashboard-page.add-field-panel.retry' | translate }}
    </button>
    <button class="light-button" (click)="next()">
      {{ 'dashboard-page.add-field-panel.next' | translate }}
    </button>
  </div>
</div>
