import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CoordinateViewModel } from 'src/app/pages/dashboard/models/field.model';
import { DashboardService } from 'src/app/pages/dashboard/services/dashboard.service';
import { DashboardPartialState, GPSFieldDesignationState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import * as DashboardActions from '../../../../state/dashboard.actions';
import * as DashboardQueries from '../../../../state/dashboard.selectors';
import { ValidTypeEnum } from '../../models/methods.enum';

@Component({
  selector: 'dfarm-add-field-with-gps',
  templateUrl: './add-field-with-gps.component.html',
  styleUrls: ['./add-field-with-gps.component.scss'],
})
export class AddFieldWithGpsComponent implements OnInit, OnDestroy {
  @Input() startGPSFieldDesignationDisabled = true;

  subs: Subscription[] = [];
  coordinates: CoordinateViewModel[] = [];
  areaSize: number;
  isValidField: ValidTypeEnum = 3;
  validTypeEnum = ValidTypeEnum;

  // Ezzel állítom be, hogy melyik a kezdő panel
  activePanel: string;

  init = true;
  designating = false;

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService,
    private dashboardService: DashboardService,
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedCoordinates))
        .subscribe(coordinates => (this.coordinates = coordinates)),
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedCoordinatesArea))
        .subscribe(areaSize => (this.areaSize = areaSize)),
      this.dashboardStore.pipe(select(DashboardQueries.getActivePanel)).subscribe(activePanel => {
        // Ha átnavigálunk az első vagy az utolsó panelra, akkor törli az adatokat
        if (activePanel === 'panel1' || activePanel === 'panel3') {
          this.isValidField = ValidTypeEnum.unknown;

          if (activePanel === 'panel1') {
            this.init = true;
            this.designating = false;
          }
        }
      }),
      this.dashboardStore
        .pipe(select(DashboardQueries.getGPSFieldDesignationState))
        .subscribe(gpsFieldDesignationState => {
          if (gpsFieldDesignationState === GPSFieldDesignationState.INIT) {
            this.init = true;
            this.designating = false;
          } else if (gpsFieldDesignationState === GPSFieldDesignationState.DESIGNATING) {
            this.init = false;
            this.designating = true;
          } else if (gpsFieldDesignationState === GPSFieldDesignationState.DESIGNATED) {
            this.init = false;
            this.designating = false;
          }
        }),
    );
  }

  startGPSFieldDesignation(): void {
    this.dashboardStore.dispatch(DashboardActions.startGPSFieldDesignation());
  }

  finishFieldDesignation(): void {
    this.dashboardStore.dispatch(DashboardActions.finishGPSFieldDesignation());
  }

  resetFieldDesignation(): void {
    this.isValidField = ValidTypeEnum.unknown;
    this.dashboardStore.dispatch(DashboardActions.selectGPSFieldDesignation());
  }

  next(): void {
    this.dashboardService.validateField(this.coordinates, this.areaSize).subscribe(
      res => {
        this.isValidField = ValidTypeEnum.valid;

        this.dashboardStore.dispatch(DashboardActions.changeAddFieldPanel({ activePanel: 'panel3' }));
        this.dashboardStore.dispatch(DashboardActions.nameFieldGPSFieldDesignation());
      },
      err => {
        this.isValidField = ValidTypeEnum.invalid;
        this.snackbarService.openErrorSnackBar(
          this.translateService.instant('dashboard-page.add-field-panel.invalid-field'),
        );
        console.error(err);
      },
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
