import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePanelComponent } from '../base-panel.component';
import { PanelComponent } from '../../../../shared/components/panel/panel.component';
import { RoleTypesEnum } from '../../../../shared/enums/role-type.enum';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import * as CoreQueries from '../../../../core/state/core.selectors';
import * as DashboardActions from '../../state/dashboard.actions';
import { CorePartialState } from '../../../../core/state/core.reducer';
import { DashboardPartialState } from '../../state/dashboard.reducer';
import { SensorDataLocations } from '../../models/sensor-data.model';
import { DeviceLocations } from '../../models/sensor-data.model-v2';
import { FieldDetailsViewModel } from '../../models/field.model';
import { SensorDataService } from '../../services/sensor-data.service';
import { SensorDataServiceV2 } from '../../services/sensor-data.service-v2';
import { AuthService } from '../../../login/auth.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SoilProbeViewerComponent } from './components/soil-probe-viewer/soil-probe-viewer.component';
import * as DashboardQueries from '../../state/dashboard.selectors';

@Component({
  selector: 'dfarm-soil-probe',
  standalone: true,
  imports: [CommonModule, PanelComponent, TranslateModule, ButtonComponent, MatTabsModule, SoilProbeViewerComponent],
  templateUrl: './soil-probe.component.html',
  styleUrl: './soil-probe.component.scss',
})
export class SoilProbeComponent extends BasePanelComponent implements OnInit, OnDestroy {
  @Output() selectNextFieldOutput: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectPreviousFieldOutput: EventEmitter<void> = new EventEmitter<void>();

  selectedField: FieldDetailsViewModel;
  sensorDataLocations: SensorDataLocations;
  hasSensorDataLocation: boolean = false;

  maxDate: string;
  intervalStart: Date;
  intervalEnd: Date;
  deviceLocations: DeviceLocations;
  hasDeviceLocation: boolean;

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly sensorDataService: SensorDataService,
    private readonly sensorDataServiceV2: SensorDataServiceV2,
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.maxDate = new Date().toISOString().slice(0, 10);
    this.intervalEnd = new Date();
    this.intervalStart = new Date();
    this.intervalStart.setDate(this.intervalEnd.getDate() - 6);

    this.subs.push(
      // GET Field Inspector Menu is open
      this.coreStore.pipe(select(CoreQueries.getSoilProbePanelIsOpen)).subscribe(panelIsOpen => {
        this.panelIsOpen = panelIsOpen;

        this.isLoading = false;

        if (panelIsOpen) {
          this.getSensorDataLocations();
        } else {
          this.dashboardStore.dispatch(
            DashboardActions.setSensorDataLocations({
              sensorDataLocations: undefined,
            }),
          );
        }
      }),
    );
    this.subs.push(
      this.dashboardStore
        .pipe(select(DashboardQueries.getSelectedField))
        .subscribe((selectedField: FieldDetailsViewModel) => {
          if (selectedField !== undefined) {
            this.selectedField = selectedField;

            if (this.panelIsOpen) {
              this.getSensorDataLocations();
            }
          }
        }),
    );
    this.subs.push(this.authService.userRoles.subscribe((roles: RoleTypesEnum[]) => (this.userRoles = roles)));
  }

  intervalStartChanged(intervalStart: Date): void {
    this.intervalStart = intervalStart;
    this.getSensorDataLocations();
  }

  intervalEndChanged(intervalEnd: Date): void {
    this.intervalEnd = intervalEnd;
    this.getSensorDataLocations();
  }

  selectNextField(): void {
    this.selectNextFieldOutput.emit();
  }

  selectPreviousField(): void {
    this.selectPreviousFieldOutput.emit();
  }

  getTranslationTitle = (translationPath: string): string => this.translateService.instant(translationPath);

  private getSensorDataLocations(): void {
    if (this.selectedField === undefined) {
      return;
    }
    this.sensorDataService
      .getSensorDataLocations(new Date(this.intervalStart), new Date(this.intervalEnd), this.selectedField.id)
      .subscribe((sensorDataLocations: SensorDataLocations) => {
        this.sensorDataLocations = sensorDataLocations;
        this.hasSensorDataLocation =
          this.sensorDataLocations.gatewayDataLocations.length > 0 ||
          this.sensorDataLocations.nodeDataLocations.length > 0;

        this.dashboardStore.dispatch(DashboardActions.setSensorDataLocations({ sensorDataLocations }));
      });
    this.sensorDataServiceV2
      .getDeviceLocations(new Date(this.intervalStart), new Date(this.intervalEnd), this.selectedField.id)
      .subscribe((deviceLocations: DeviceLocations) => {
        console.log('deviceLocations', deviceLocations);
        this.deviceLocations = deviceLocations;
        this.hasDeviceLocation = this.deviceLocations.deviceLocations.length > 0;

        this.dashboardStore.dispatch(DashboardActions.setDeviceLocations({ deviceLocations }));
      });
  }

  get hasPermission(): boolean {
    return (
      this.userRoles.some((roleTypesEnum: RoleTypesEnum) => roleTypesEnum === this.RoleTypesEnum.Sensor) ||
      this.userRoles.some((roleTypesEnum: RoleTypesEnum) => roleTypesEnum === this.RoleTypesEnum.Trial)
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
