import { Action, createReducer, on } from '@ngrx/store';
import * as fromCore from './core.actions';

export const CORE_FEATURE_KEY = 'core';

export interface State {
  addFieldPanelIsOpen: boolean;
  fieldInspectorIsOpen: boolean;
  dfarmServicesIsOpen: boolean;
  sideMenuIsOpen: boolean;
  informationPanelIsOpen: boolean;
  dataViewerPanelIsOpen: boolean;
  satelliteViewerPanelIsOpen: boolean;
  sensorViewerPanelIsOpen: boolean;
  waterScopeItemListPanelIsOpen: boolean;
  waterScopeTablePanelIsOpen: boolean;
  waterScopeChartPanelIsOpen: boolean;
  soilProbePanelIsOpen: boolean;
}

export interface CorePartialState {
  readonly [CORE_FEATURE_KEY]: State;
}

export const initialState: State = {
  addFieldPanelIsOpen: false,
  fieldInspectorIsOpen: false,
  dfarmServicesIsOpen: false,
  sideMenuIsOpen: false,
  informationPanelIsOpen: false,
  dataViewerPanelIsOpen: false,
  satelliteViewerPanelIsOpen: false,
  sensorViewerPanelIsOpen: false,
  waterScopeItemListPanelIsOpen: false,
  waterScopeTablePanelIsOpen: false,
  waterScopeChartPanelIsOpen: false,
  soilProbePanelIsOpen: false,
};

const coreReducer = createReducer(
  initialState,
  // LOAD Core Data
  on(fromCore.loadCoreData, state => ({ ...state })),
  on(
    fromCore.closeAllPanel,
    (): State => ({
      sideMenuIsOpen: false,
      addFieldPanelIsOpen: false,
      dfarmServicesIsOpen: false,
      fieldInspectorIsOpen: false,
      informationPanelIsOpen: false,
      dataViewerPanelIsOpen: false,
      satelliteViewerPanelIsOpen: false,
      sensorViewerPanelIsOpen: false,
      waterScopeItemListPanelIsOpen: false,
      waterScopeTablePanelIsOpen: false,
      waterScopeChartPanelIsOpen: false,
      soilProbePanelIsOpen: false,
    }),
  ),

  // Control Side Menu
  on(fromCore.openSideMenu, state => ({ ...state, sideMenuIsOpen: true })),
  on(fromCore.closeSideMenu, state => ({ ...state, sideMenuIsOpen: false })),

  // Control Add Field Panel
  on(fromCore.openAddFieldPanel, state => ({
    ...state,
    addFieldPanelIsOpen: true,
  })),
  on(fromCore.closeAddFieldPanel, state => ({
    ...state,
    addFieldPanelIsOpen: false,
  })),

  // Control Field Inspector Panel
  on(fromCore.openFieldInspectorPanel, state => ({
    ...state,
    fieldInspectorIsOpen: true,
  })),
  on(fromCore.closeFieldInspectorPanel, state => ({
    ...state,
    fieldInspectorIsOpen: false,
  })),

  // Control Dfarm Services Panel
  on(fromCore.openDfarmServicesPanel, state => ({
    ...state,
    dfarmServicesIsOpen: true,
  })),
  on(fromCore.closeDfarmServicesPanel, state => ({
    ...state,
    dfarmServicesIsOpen: false,
  })),

  // Control Dfarm Information Panel
  on(fromCore.openInformationPanel, state => ({
    ...state,
    informationPanelIsOpen: true,
  })),
  on(fromCore.closeInformationPanel, state => ({
    ...state,
    informationPanelIsOpen: false,
  })),

  // Control Data Viewer Panel
  on(fromCore.openDataViewerPanel, state => ({
    ...state,
    dataViewerPanelIsOpen: true,
  })),
  on(fromCore.closeDataViewerPanel, state => ({
    ...state,
    dataViewerPanelIsOpen: false,
  })),

  // Control Satellite Viewer Panel
  on(fromCore.openSatelliteViewerPanel, state => ({
    ...state,
    satelliteViewerPanelIsOpen: true,
  })),
  on(fromCore.closeSatelliteViewerPanel, state => ({
    ...state,
    satelliteViewerPanelIsOpen: false,
  })),

  // Control Sensor Viewer Panel
  on(fromCore.openSensorViewerPanel, state => ({
    ...state,
    sensorViewerPanelIsOpen: true,
  })),
  on(fromCore.closeSensorViewerPanel, state => ({
    ...state,
    sensorViewerPanelIsOpen: false,
  })),

  // Control WaterScope Item List Panel
  on(fromCore.openWaterscopeItemListPanel, state => ({
    ...state,
    waterScopeItemListPanelIsOpen: true,
  })),
  on(fromCore.closeSensorViewerPanel, state => ({
    ...state,
    waterScopeItemListPanelIsOpen: false,
  })),

  // Control WaterScope Table Panel
  on(fromCore.openWaterscopeTablePanel, state => ({
    ...state,
    waterScopeTablePanelIsOpen: true,
  })),
  on(fromCore.closeWaterscopeTablePanel, state => ({
    ...state,
    waterScopeTablePanelIsOpen: false,
  })),

  // Control WaterScope Chart Panel
  on(fromCore.openWaterscopeChartPanel, state => ({
    ...state,
    waterScopeChartPanelIsOpen: true,
  })),
  on(fromCore.closeWaterscopeChartPanel, state => ({
    ...state,
    waterScopeChartPanelIsOpen: false,
  })),

  // Control Soil Probe Panel
  on(fromCore.openSoilProbePanel, state => ({
    ...state,
    soilProbePanelIsOpen: true,
  })),
  on(fromCore.closeSoilProbePanel, state => ({
    ...state,
    soilProbePanelIsOpen: false,
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return coreReducer(state, action);
}
