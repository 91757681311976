import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dfarm-edit-field-dialog',
  templateUrl: './edit-field-dialog.component.html',
  styleUrls: ['./edit-field-dialog.component.scss'],
})
export class EditFieldDialogComponent implements OnInit {
  fieldName = new FormControl('', Validators.required);
  constructor(
    public dialogRef: MatDialogRef<EditFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.fieldName.patchValue(this.data);
    }
  }

  save(): void {
    if (this.fieldName.valid) {
      this.dialogRef.close(this.fieldName.value);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
