<div class="flex flex-col items-center h-fit text-center text-white w-full desktop:p-2">
  <div class="flex-col tabletHorizontal:flex-row flex gap-2 w-full">
    <div class="flex flex-col gap-2 flex-1 bg-light-green rounded-lg shadow-sunkenBoxShadow">
      <label>{{ 'dashboard-page.data-viewer-panel.temperature' | translate }}</label>
      <div class="flex gap-2 items-center justify-center">
        <label class="text-off-white text-lg"
          >{{ 'dashboard-page.data-viewer-panel.min' | translate }} {{ kpiMinimumTemperature }} °C</label
        >
        <mat-divider vertical class="h-4" />
        <label class="text-off-white text-lg"
          >{{ 'dashboard-page.data-viewer-panel.max' | translate }} {{ kpiMaximumTemperature }} °C</label
        >
      </div>
    </div>
    <div class="flex flex-col gap-2 flex-1 bg-light-green rounded-lg shadow-sunkenBoxShadow">
      <label>{{ 'dashboard-page.data-viewer-panel.rain' | translate }}</label>
      <label class="text-off-white text-lg"
        >{{ kpiPrecipitation }} {{ 'dashboard-page.data-viewer-panel.mm' | translate }}</label
      >
    </div>
  </div>
  <form [formGroup]="dateRangeSelectorForm" class="w-full text-sm mb-2 flex justify-evenly items-center">
    <div class="text-dark-green">
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="startDate"
          required
          [max]="formControls.endDate.value" />
      </label>
      -
      <label>
        <input
          type="date"
          class="bg-transparent w-28 text-center text-sm"
          formControlName="endDate"
          required
          [max]="maxDate"
          [min]="formControls.startDate.value" />
      </label>
    </div>
  </form>
  <div
    class="h-full w-full flex flex-col padding-2 mobileVertical:overflow-auto desktop:overflow-auto tabletHorizontal:overflow-auto padding-2 rounded-lg">
    <!-- <div
    class="h-full w-full flex flex-col overflow-auto padding-2 rounded-[10px]"
  > -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #airTemperatureCanvas id="airTemperatureCanvas"></canvas>
    </div>
    <!-- <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageAirTemperatureCanvas
        id="cumulativeAverageAirTemperatureCanvas"
      ></canvas>
    </div> -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #soilTemperatureT0Canvas id="soilTemperatureT0Canvas"></canvas>
    </div>
    <!-- <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageSoilTemperatureT0Canvas
        id="cumulativeAverageSoilTemperatureT0Canvas"
      ></canvas>
    </div> -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #soilTemperatureT10Canvas id="soilTemperatureT10Canvas"></canvas>
    </div>
    <!-- <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageSoilTemperatureT10Canvas
        id="cumulativeAverageSoilTemperatureT10Canvas"
      ></canvas>
    </div> -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #precipitationCanvas id="precipitationCanvas"></canvas>
    </div>
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #cumulativePrecipitationCanvas id="cumulativePrecipitationCanvas"></canvas>
    </div>
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #humidityCanvas id="humidityCanvas"></canvas>
    </div>
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas #uviCanvas id="uviCanvas"></canvas>
    </div>
  </div>
</div>
