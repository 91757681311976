import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'dfarm-activate-profile',
  templateUrl: './activate-profile.component.html',
  styleUrls: ['./activate-profile.component.scss'],
})
export class ActivateProfileComponent implements OnInit {
  token: string;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.token = params.Token;
        this.activateProfile();
      }
    });
  }

  activateProfile(): void {
    this.authService.activateProfile(this.token).subscribe(() => (this.isLoading = false));
  }

  navigateToLoginPage(): void {
    this.router.navigate(['/login']);
  }
}
