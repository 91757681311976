<form class="dateRangeSelectorForm" [formGroup]="dateRangeSelectorForm" (ngSubmit)="onSubmit()">
  <div>
    Mérési időszak:
    <label>
      <input type="date" class="date-picker" formControlName="startDate" required [max]="maxDate" />
    </label>
    -
    <label>
      <input type="date" class="date-picker" formControlName="endDate" required [max]="maxDate" />
    </label>
  </div>
  <button type="submit" [disabled]="!dateRangeSelectorForm.valid" class="submit-button">Lekérdez</button>
</form>

<div class="satelliteImageContainer">
  <div>
    Műholdas felvételek:
    <button
      class="satelliteImageSelectorButton"
      [ngClass]="{
        satelliteImageSelectorBlurredButton: selectedImageType !== TRUE_COLOR,
      }"
      (click)="selectImageType(TRUE_COLOR)">
      True Color
    </button>
    <button
      class="satelliteImageSelectorButton"
      [ngClass]="{
        satelliteImageSelectorBlurredButton: selectedImageType !== NDVI,
      }"
      (click)="selectImageType(NDVI)">
      NDVI
    </button>
  </div>
  <div class="satelliteImageSelector">
    <div class="sliderWrapper">
      <label>{{ dateLabel }}</label>
      <!-- <mat-slider
				[min]="sliderMin"
				[max]="sliderMax"
				[value]="sliderValue"
			></mat-slider> -->
      <div>
        <button class="satelliteImageSelectorButton" (click)="previousImage()">Előző</button>
        <button class="satelliteImageSelectorButton" (click)="playClicked()">
          {{ playButtonOn ? STOP : PLAY }}
        </button>
        <button class="satelliteImageSelectorButton" (click)="nextImage()">Következő</button>
      </div>
    </div>
  </div>
</div>

<div class="chart-container">
  <canvas #airTemperatureCanvas id="airTemperatureCanvas" width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas
    #cumulativeAverageAirTemperatureCanvas
    id="cumulativeAverageAirTemperatureCanvas"
    width="200"
    height="100"></canvas>
</div>
<div class="chart-container">
  <canvas #soilTemperatureT0Canvas id="soilTemperatureT0Canvas" width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas
    #cumulativeAverageSoilTemperatureT0Canvas
    id="cumulativeAverageSoilTemperatureT0Canvas"
    width="200"
    height="100"></canvas>
</div>
<div class="chart-container">
  <canvas #soilTemperatureT10Canvas id="soilTemperatureT10Canvas" width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas
    #cumulativeAverageSoilTemperatureT10Canvas
    id="cumulativeAverageSoilTemperatureT10Canvas"
    width="200"
    height="100"></canvas>
</div>
<div class="chart-container">
  <canvas #precipitationCanvas id="precipitationCanvas" width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas #cumulativePrecipitationCanvas id="cumulativePrecipitationCanvas" width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas #humidityCanvas id="humidityCanvas" width="200" height="100"></canvas>
</div>
