<div class="details-wrapper">
  <div class="left-side">
    <div class="area-wrapper">
      <div class="area bg-light-green text-off-white">
        <div class="area-title">
          {{ 'dashboard-page.field-inspector-panel.field-size' | translate }}
        </div>
        <div class="area-size">{{ selectedField?.areaSize }} ha</div>
      </div>
    </div>
    <div class="area-wrapper">
      <div class="area bg-light-green text-off-white">
        <div class="area-title">
          {{ 'dashboard-page.field-inspector-panel.location' | translate }}
        </div>
        <div class="area-size">{{ location }}</div>
      </div>
    </div>
  </div>
  <div class="right-side">
    <!-- TODO: visszarakni és átbeszélni, hogy milyen szolgáltatások lesznek -->
    <!-- <div class="checkbox-wrapper">
      <div class="services">
        <div class="serivce-icon"></div>
        <div class="service-title">Műhold szolgáltatás</div>
      </div>
      <div class="services">
        <div class="serivce-icon"></div>
        <div class="service-title">Drón szolgáltatás</div>
      </div>
      <div class="services">
        <div class="serivce-icon"></div>
        <div class="service-title">Szenzor szolgáltatás</div>
      </div>
    </div> -->
  </div>
</div>
<div class="bottom-buttons">
  <!-- TODO: visszarakni őket -->
  <!-- <button class="light-button">Download KML</button>
    <button class="light-button">Szolgáltatások</button> -->
</div>
