<div
  class="panel"
  [ngClass]="{
    '!fixed': !isOverflowScrollablePanel || (isOverflowScrollablePanel && !isVisible),
    'overflow-scrollable-panel': isOverflowScrollablePanel,
    'multi-view-panel': isMultiViewPanel,
    'right-slide-over': isRightSlideOver,
    left: isLeftSide,
    right: !isLeftSide,
    open: isVisible && !isRightSlideOver && !isSlideDown,
    block: wrapperIsBlock,
    'wide-panel': isWidePanel,
    'small-panel': isSmallPanel,
  }">
  <dfarm-close-button
    (click)="closePanel()"
    *ngIf="isClosable"
    class="absolute top-4 right-4 z-20"></dfarm-close-button>
  <div
    *ngIf="isOverflowScrollablePanel"
    class="absolute top-1 left-[calc(50%-48px)] h-1 w-24 rounded-sm bg-slate-400 block tabletHorizontal:block mobileVertical:hidden desktop:hidden"></div>
  <!-- Ez a rész akkor jelenik meg, ha nem lehet lapozni a panelen belül. -->
  <ng-content *ngIf="!isSlideable"></ng-content>

  <!-- Ez a rész akkor jelenik meg, ha lehet lapozni a panelen belül.
        Ekkor a megfelelő panelre rá kell rakni a kívánt direktívát -->
  <div class="panes h-full flex" [@slide]="activePanel" *ngIf="isSlideable">
    <div>
      <ng-content select="[panel1]"></ng-content>
    </div>
    <div>
      <ng-content select="[panel2]"></ng-content>
    </div>
    <div>
      <ng-content select="[panel3]"></ng-content>
    </div>
    <div>
      <ng-content select="[panel4]"></ng-content>
    </div>
  </div>
</div>
