import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'dfarm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formControls: Record<keyof any, FormControl> = {
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  };
  loginFormGroup = new FormGroup(this.formControls);

  constructor(
    private router: Router,
    private authService: AuthService,
    private deviceDetectorService: DeviceDetectorService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {}

  login(): void {
    if (this.loginFormGroup.invalid) {
      this.snackbarService.openErrorSnackBar('Hibás adatok!');
      return;
    }
    this.authService
      .loginUser(this.loginFormGroup.controls.username.value, this.loginFormGroup.controls.password.value)
      .subscribe(
        () => {
          this.router.navigate(['/dashboard']).then(() => {
            if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
              window.location.reload();
            }
          });
        },
        error => this.snackbarService.openErrorSnackBar(error.error.errorMessage),
      );
  }

  register(): void {
    this.router.navigate(['/registration']);
  }

  forgotPassword(): void {
    this.router.navigate(['/forget-password']);
  }
}
