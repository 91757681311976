<dfarm-panel
  *ngIf="!isLoading"
  [isLeftSide]="true"
  [isVisible]="panelIsOpen"
  [wrapperIsBlock]="true"
  class="dfarm-menu">
  <div class="list-wrapper p-2">
    <ul class="main-list">
      <li class="flex flex-col gap-1">
        <h2>{{ 'menu.fields-manager' | translate }}</h2>
        <h3
          (click)="showFieldInspectorPanel(); $event.stopPropagation()"
          class="flex items-center cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <img src="assets/menu-icons/list.svg" />
          {{ 'menu.field-list' | translate }}
        </h3>
        <h3
          (click)="showAddFieldPanel(); $event.stopPropagation()"
          class="flex items-center cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <img src="assets/menu-icons/add-new-field.svg" />
          {{ 'menu.add-field' | translate }}
        </h3>
      </li>

      <mat-divider></mat-divider>

      <li class="flex flex-col gap-1">
        <h2>{{ 'menu.reports' | translate }}</h2>
        <h3
          (click)="showDataViewerPanel(hasBasicPermission); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="../../../../../assets/menu-icons/weather-data.svg" />
            {{ 'menu.weather-data' | translate }}
          </div>
          <mat-icon *ngIf="!hasBasicPermission">lock</mat-icon>
        </h3>
        <h3
          (click)="showSatelliteViewerPanel(hasBasicPermission); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="assets/menu-icons/satellite.svg" />
            {{ 'menu.satellite-data' | translate }}
          </div>
          <mat-icon *ngIf="!hasBasicPermission">lock</mat-icon>
        </h3>
        <h3
          (click)="showSensorViewerPanel(hasSensorPermission); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="assets/menu-icons/windsock.svg" />
            {{ 'menu.sensor-data' | translate }}
          </div>
          <mat-icon *ngIf="!hasSensorPermission">lock</mat-icon>
        </h3>
        <h3
          (click)="showSoilProbePanel(hasSensorPermission); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="assets/menu-icons/soil-probe.png" />
            {{ 'menu.soil-probe' | translate }}
          </div>
          <mat-icon *ngIf="!hasSensorPermission">lock</mat-icon>
        </h3>
      </li>

      <mat-divider></mat-divider>

      <li class="flex flex-col gap-1">
        <h2>Vízhasználati jelentések</h2>
        <h3
          (click)="showWaterscopeItemListPanel(!isProduction); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="../../../../../assets/menu-icons/overviewIcon.svg" />
            Eszközök listája
          </div>
          <mat-icon *ngIf="isProduction">lock</mat-icon>
        </h3>
        <h3
          (click)="showWaterscopeTablePanel(!isProduction); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="../../../../../assets/menu-icons/addFieldIcon.svg" />
            Táblázat
          </div>
          <mat-icon *ngIf="isProduction">lock</mat-icon>
        </h3>
        <h3
          (click)="showWaterscopeChartPanel(!isProduction); $event.stopPropagation()"
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg">
          <div class="flex items-center">
            <img src="../../../../../assets/menu-icons/addFieldIcon.svg" />
            Grafikon
          </div>
          <mat-icon *ngIf="isProduction">lock</mat-icon>
        </h3>
      </li>

      <!--      <mat-divider></mat-divider>-->

      <!--      <li class="main-list-item" customClassName="open-menu-list-item" dfarmExpandMenu>-->
      <!--        <div class="title-wrapper">-->
      <!--          <img class="arrow-icon" src="../../../../../assets/menu-icons/arrowIcon.svg"/>-->
      <!--          {{ 'menu.help' | translate }}-->
      <!--        </div>-->
      <!--        <ul class="sub-list">-->
      <!--          <li>-->
      <!--            <div (click)="navigateToDfarm()" class="subtitle-wrapper">-->
      <!--              <img src="../../../../../assets/menu-icons/contactIcon.svg"/>-->
      <!--              {{ 'menu.contact' | translate }}-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
    </ul>
  </div>
</dfarm-panel>
