import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageTypes } from '../../models/languages.model';
import { changeTranslate } from '../../utils/translate.setter';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'dfarm-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, TranslateModule, ReactiveFormsModule, NgOptimizedImage],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  @Input() isWhite = false;
  subs = new Subscription();
  selectedLanguage = new FormControl('', Validators.required);

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    this.selectedLanguage.patchValue(localStorage.getItem('language') ?? environment.defaultLanguage);
    this.subs.add(
      this.selectedLanguage.valueChanges.subscribe((language: LanguageTypes) =>
        changeTranslate(this.translate, language),
      ),
    );

    this.isWhite
      ? document.documentElement.style.setProperty('--mat-mdc-select-color', '#FFFFFF')
      : document.documentElement.style.setProperty('--mat-mdc-select-color', '#000000');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
