<dfarm-panel
  *ngIf="!isLoading"
  [activePanel]="activePanel"
  [isClosable]="true"
  [isLeftSide]="false"
  [isMultiViewPanel]="true"
  [isSlideable]="true"
  [isVisible]="panelIsOpen"
  class="parent-panel">
  <div class="child-panel" panel1>
    <div class="title text-xl">Öntözők listázása</div>
    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <div class="input-wrapper">
        <div class="search-icon">
          <img alt="search" class="h-9" src="../../../../../assets/field-inspection-icons/search.svg" />
        </div>
        <input [formControl]="searchField" class="text-base" name="search" type="text" />
      </div>
      <div *ngIf="wateringDevices.length > 0 && filteredWateringDevices.length > 0" class="field-list">
        <div
          (click)="selectWateringDevice(wateringDevice)"
          *ngFor="let wateringDevice of filteredWateringDevices"
          class="field-list-item">
          <div class="field-list-item-title text-lg">{{ wateringDevice.name }}</div>
          <div class="icons flex gap-2">
            <dfarm-button>
              <img alt="edit" src="../../../../../assets/field-inspection-icons/edit.svg" />
            </dfarm-button>
            <dfarm-button>
              <img alt="delete" src="../../../../../assets/field-inspection-icons/trash.svg" />
            </dfarm-button>
          </div>
        </div>
      </div>
      <div *ngIf="wateringDevices.length === 0" class="field-list">
        <div class="flex flex-col justify-center items-center gap-1 h-full text-white">
          <div>
            {{ 'dashboard-page.field-inspector-panel.no-fields' | translate }}
          </div>
          <div>
            {{ 'dashboard-page.field-inspector-panel.please-add-new-field' | translate }}
          </div>
          <dfarm-button [lightGreenButton]="true">
            {{ 'dashboard-page.field-inspector-panel.add-new-field' | translate }}
          </dfarm-button>
        </div>
      </div>
      <div *ngIf="filteredWateringDevices.length === 0" class="field-list">
        <div class="flex flex-col justify-center items-center gap-1 h-full text-white">
          <div>
            {{ 'dashboard-page.field-inspector-panel.no-fields-found' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="child-panel" panel2>
    <img
      (click)="backToList()"
      alt="back"
      class="back-icon ml-4"
      src="../../../../../assets/field-inspection-icons/arrow.svg" />
    <div *ngIf="selectedWateringDevice()" class="title text-xl">{{ selectedWateringDevice().name }}</div>
    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <div class="grid grid-cols-1 gap-4 mobileVertical:grid-cols-2">
        <div class="area">
          <div class="area-title">
            <!--          {{ 'dashboard-page.field-inspector-panel.field-size' | translate }}-->
            Nyomás
          </div>
          <div class="area-size">10 Pa</div>
        </div>
        <div class="area">
          <div class="area-title">
            <!--          {{ 'dashboard-page.field-inspector-panel.field-size' | translate }}-->
            Átfolyó vízmennyiség
          </div>
          <div class="area-size">100 liter/óra</div>
        </div>
        <div class="area">
          <div class="area-title">
            <!--          {{ 'dashboard-page.field-inspector-panel.field-size' | translate }}-->
            Üzemanyagszint
          </div>
          <div class="area-size">80%</div>
        </div>
        <div class="area">
          <div class="area-title">
            <!--          {{ 'dashboard-page.field-inspector-panel.field-size' | translate }}-->
            Feszültség
          </div>
          <div class="area-size">10 V</div>
        </div>
      </div>
    </div>
  </div>
</dfarm-panel>
